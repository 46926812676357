import { useContext } from "react";
import { getFormattedAddress } from "../../Util/AddressUtil";
import Modal from "../Modal";
import Text, { TextSize, TextWeight } from "../Text";
import Button from "../Button";
import styles from "./UnverifiedAddressModal.module.scss";
import LocaleContext from "../../Util/LocaleContext";

const UnverifiedAddressModal = ({ close, submitFormToServer, userAddress }) => {
  const onSubmit = () => {
    close();
    submitFormToServer(userAddress);
  };

  const getLocalizedString = useContext(LocaleContext);

  return (
    <Modal onClose={close}>
      <div className={styles.container}>
        <Text size={TextSize.Large} weight={TextWeight.Bold}>
          {getLocalizedString("unverified-address-modal_header")}
        </Text>
        <Text>{getLocalizedString("unverified-address-modal_subheader")}</Text>
        <div className={styles.address}>{getFormattedAddress(userAddress)}</div>
        <div className={styles.actionButtonRow}>
          <Button onClick={onSubmit}>
            {getLocalizedString("unverified-address-modal_confirm")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UnverifiedAddressModal;
