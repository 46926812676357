export function makeAddressVerificationRequest(
  address,
  onComplete,
  onRejected,
  onAccepted,
  onModified,
  onError,
) {
  // If we don't support verification, return accepted with the address as-is
  if (!GoogleSupportedCountries.includes(address.regionCode)) {
    onComplete();
    onAccepted(address);
    return;
  }

  fetch(
    "https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyB2MHDINlNGmvI_4XXghjT6tMsik0sdwcw",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ address }),
    },
  )
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 400) {
        onComplete();
        onRejected();
      } else {
        onError();
      }
    })
    .then((verificationResponse) => {
      const result = verificationResponse?.result;
      const verdict = result?.verdict;

      const addressComplete = verdict?.addressComplete ?? false;
      const hasInferredComponents = verdict?.hasInferredComponents ?? false;

      console.log(result);

      onComplete();
      if (!addressComplete) {
        onRejected();
      } else if (hasInferredComponents) {
        onModified(result?.address?.postalAddress);
      } else {
        onAccepted(result?.address?.postalAddress);
      }
    })
    .catch(() => {
      onError();
    });
}

const GoogleSupportedCountries = [
  "AR",
  "AU",
  "AT",
  "BE",
  "BR",
  "BG",
  "CA",
  "CL",
  "CO",
  "HR",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MY",
  "MX",
  "NL",
  "NO",
  "NZ",
  "PL",
  "PT",
  "PR",
  "SG",
  "SK",
  "SI",
  "ES",
  "SE",
  "CH",
  "GB",
  "US",
];
